import type { FC } from 'react';
import useUser from 'utils/useUser';

interface IProductPricesProps {
	rrp: number | null;
	yourPrice: number;
}

const ProductPrices: FC<IProductPricesProps> = ({ yourPrice, rrp }) => {
	const { isLoggedIn } = useUser();

	return (
		<div>
			{isLoggedIn ? (
				<span className="flex items-center justify-center">
					<span className="flex flex-col font-medium items-center">
						<span className="text-md inline-flex items-center">
							<span className="text-sm relative -top-2">$</span>
							{yourPrice}
						</span>
						<span className="text-brand text-2xs">Your price ex GST</span>
					</span>
					{rrp && (
						<span className="flex flex-col font-medium border-l border-gray pl-3 ml-3">
							<span className="text-md inline-flex items-center">
								<span className="text-sm relative -top-2">$</span>
								{rrp}
							</span>
							<span className="text-brand text-2xs">RRP</span>
						</span>
					)}
				</span>
			) : (
				<span>Login to see price</span>
			)}
		</div>
	);
};

export default ProductPrices;
