import React, { forwardRef, useEffect, useState } from 'react';
import Button from '@b2b-frontend/components/Button';
import clsx from 'clsx';
import Link from 'next/link';
import QuantityDropdown from '@b2b-frontend/components/QuantityDropdown';
import Heading from '../Heading';
import { ROUTES } from '../../constants';
import useBasket from 'utils/useBasket';
import { IProductPreviewProps } from 'components/CommonProductTile';
import AddToCartButton from 'components/AddToCartButton';
import { useViewModifier } from 'utils/ViewModifierProvider';

const ProductListItem: React.FC<IProductPreviewProps> = (
	{
		className = '',
		showPrices,
		priceElement,
		onProductClicked,
		isOutOfStock,
		product: { ean, material, href, availableQuantity, shippingQuantity, name },
		addToBasket,
		updateQuantity,
	},
	ref,
) => {
	const [quantity, setQuantity] = useState('');
	const { itemMap } = useBasket();
	const { allowAddToCart } = useViewModifier();

	const itemInBasket = itemMap[material];
	const isLowStock = availableQuantity > 0 && availableQuantity < 20;

	useEffect(() => {
		setQuantity(itemInBasket ? `${itemInBasket.quantity}` : `${shippingQuantity}`);
	}, [itemInBasket, shippingQuantity]);

	const handleAddButtonPressed = async () => {
		if (!quantity) {
			return;
		}

		if (!!itemInBasket) {
			updateQuantity({ material, quantity });
		} else {
			addToBasket({ material, quantity });
		}
	};

	return (
		<div
			className={clsx(
				'flex flex-col xl:flex-row md:justify-between bg-white font-sans text-center p-8 xs:p-4 sm:p-8 border-b border-gray',
				className,
			)}
			ref={ref}
		>
			<div className="text-left text-xs">
				<Heading as={'h3'} size="text-xs" className="mb-2" serif={false}>
					<Link href={href} onClick={onProductClicked}>
						{name}
					</Link>
				</Heading>
				<p className="mb-3">
					{ean} | {material}
					{isLowStock && !isOutOfStock && (
						<span className="ml-2 text-red-600 uppercase">low stock</span>
					)}
					{showPrices && isOutOfStock && (
						<span className="ml-2 text-red-600 uppercase">out of stock</span>
					)}
				</p>
			</div>
			{allowAddToCart && (
				<div className="flex flex-col xs:flex-row justify-between items-center">
					{showPrices ? (
						<>
							{priceElement && (
								<div className="w-full xs:w-auto mb-3 xs:mb-0 border-t border-b xl:border-b-0 xl:border-t-0 border-gray py-1 md:mr-3">
									{priceElement}
								</div>
							)}
							<div className="flex items-center justify-between">
								<QuantityDropdown
									availableQuantity={availableQuantity}
									className="mr-4"
									value={quantity}
									onChange={setQuantity}
									name={`${material}_quantity`}
									id={`${material}_quantity`}
									disabled={isOutOfStock}
									quantityInterval={shippingQuantity}
									minQuantity={shippingQuantity}
								/>
								<AddToCartButton
									material={material}
									onClick={handleAddButtonPressed}
									isOutOfStock={isOutOfStock}
								/>
							</div>
						</>
					) : (
						<>
							<div className="text-xs mr-3">Login to see price</div>
							<Button href={ROUTES.API.AUTH.LOGIN} style={{ width: '160px' }}>
								Login
							</Button>
						</>
					)}
				</div>
			)}
		</div>
	);
};

export default forwardRef(ProductListItem as any);
