import React from 'react';
import SkeletonRow from '@b2b-frontend/components/SkeletonRow';

const ProductCarouselItemSkeleton: React.FC<{ className?: string }> = ({ className = '' }) => {
	return (
		<div className={className}>
			<div className="flex items-center justify-center pt-8">
				<SkeletonRow width={140} height="h-32" rounded={false} />
			</div>
			<div className="p-8 text-xs">
				<div className="flex items-center flex-col">
					<SkeletonRow className="font-sans mb-3" width={150} />
					<SkeletonRow width={110} className="mb-1" />
					<SkeletonRow width={100} className="mb-1" />
					<SkeletonRow width={125} className="mb-5" />
				</div>
				<div className="max-w-[230px] mx-auto">
					<div className="border-t border-b border-gray py-5 mb-4 flex items-center justify-center">
						<SkeletonRow width={150} />
					</div>
					<SkeletonRow width={125} className="mx-auto mb-4" />
					<SkeletonRow width={160} height="h-14" className="mx-auto" rounded={false} />
				</div>
			</div>
		</div>
	);
};

export default ProductCarouselItemSkeleton;
