import { FC } from 'react';
import { IProductPreviewProps } from './types';
import ProductGridItem from 'components/ProductGridItem';
import ProductListItem from 'components/ProductListItem';

export const getProductPreviewElementFromActiveView = (
	activeView: 'grid' | 'list',
): FC<IProductPreviewProps> => {
	switch (activeView) {
		case 'grid':
			return ProductGridItem as any;
		case 'list':
			return ProductListItem as any;
		default:
			console.error(
				`[ProductPage:renderProducts]: Received unsupported view mode - ${activeView}`,
			);
			return ProductListItem as any;
	}
};
