import { useUser } from '@auth0/nextjs-auth0/client';
import { useAlertContext } from '@b2b-frontend/components/AlertContext';
import { createProductClickEvent } from '@b2b-frontend/utils/analytics/gtm';
import { useLocale } from '@b2b-frontend/utils/sites';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import { useSWRConfig } from 'swr';
import useBasket from 'utils/useBasket';

export const useViewModel = ({ product, activeView }) => {
	const { addAlert } = useAlertContext();
	const { mutate } = useSWRConfig();
	const { basket, itemMap } = useBasket();
	const sendDataToGTM = useGTMDispatch();
	const { user } = useUser();
	const locale = useLocale();
	const isOutOfStock = product.availableQuantity < product.shippingQuantity;

	const handleProductClicked = () => {
		sendDataToGTM(createProductClickEvent({ clickAreaName: activeView, product }));
	};

	return {
		addAlert,
		mutate,
		basket,
		itemMap,
		sendDataToGTM,
		user,
		locale,
		isOutOfStock,
		handleProductClicked,
	};
};
