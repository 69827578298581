import React, { useEffect, useState } from 'react';
import Image from 'next/legacy/image';
import CameraIcon from '@b2b-frontend/components/icons/Camera';

interface IProductImagePreviewProps {
	images: string[];
}

const ProductImagePreview: React.FC<IProductImagePreviewProps> = ({ images = [] }) => {
	const [imgLoadError, setImgLoadError] = useState(false);

	useEffect(() => {
		if (images?.length > 0) {
			setImgLoadError(false);
		}
	}, [images]);

	if (images?.length && !imgLoadError) {
		return (
			<div className="max-w-[200px] max-h-[200px]">
				<Image
					src={`${process.env.NEXT_PUBLIC_CDN_URL}/400/id/${images[0]}`}
					width={400}
					height={400}
					placeholder="blur"
					blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAABM1JREFUWEedl4tyozoQRBvQCyd3//87d20jJCHd6pHAgJ1NvKSoVNkGHfXMtGa6UkrBW1dGShFLisg5gw93fY+uVxiUwtAr9G+8r3sXoJS0ASwEKBWgFwBdIdD9GOEtgIKClGakGJBixJKXCtANbXENpXj/XIW3ANISEaNHCB4xBiwpoZROAJQyUNpAy10hfqLDC4A1JY6Pc7cxBYQwIcwTQpglFDl36DsFpSyMsTDWwmjCKAzDF8HgEpJ6BUcA+bAlFlOpqxD8OOWEGGfM4Q7v75iDlzDkBeh7Da0srB1hrYMxBtpoqGFA395RX1Rfxrzn3wsAJlX9quODXc1nAiw5IRBgvsPLPSGGgJyBvtMw2sKaC5xzsNYKABUgwKqlFFwDqDQ4KUCuklsGVwX4MH+acj4oQIiwASgYzYVHOEcVGIYGsKuHDYDSN0lOOVBlEQVO1bwAkgPzPMH7m6gQw4yci+SA3gAuVQFtMFDEQ0FW+bu2e375Igl3Cuwe5qdpSZjDhNnfJBQVIEsVCIDj4hcY66DFD85X3eD++jHAGoYQ7ggE8Fek4CtAr6C0g3EfsPZDACQBvwSoGr/MAaw5IAl4FJB5EOYbgr/KncKEkpcKYC7Q7hNGAEYBePaB3MpPslzef1IgQ9KaMhGgO4rIEAR/R/S/EfyfDaAfNAYB+A/GfUKbC5Q6A7ACdgCSAGcA+QHTbQVQm4gSghQR/Q1h+i0QKdylaugDA3fufokKAqDPTtg2J15Td/8CYKmUXwHEgDBfEaY/DYAhyKACynxAu18w4xsATyHYFADtDdjlsSggAEzAPzUE0TcAIzlA+Y39hLajVMExB5r82+n/MgdanDouR4BjHseFPnBvZXhFis0HBvoAS5BhuMAYBz2cAVoJ/h2AIV99oMVpV0oxR/EBngViRAQoRZoQbeiAIxyVMBa6f1UFex94WYbriSEV+lTFoVQjmvwETz+I4QGgHZwdMRKEAF3/xXH8jRFVI5YKPZkoQAAfPCZ/x0RHFIAsCljtMG4ABqZ7fRRvp+BrI2IAmCz1NNxDMDAhR1n4TgDmwk4BZ5zs/mIvcC0EZyeshzB7gIfAByPil7RWUaDrD2c5P52XgGmecPNXgZhbDqhBwekRF3dpAA5mOLZltRVoJc4N1kWOTsgfHACkFOtFewppxn2+4zZdcWM58jAqGXrQcGbEh/0UCKphlD7UkOw9PxRY+5RTR5TBTndTYAcgCsQGIApc4eMsx6vqG4D7FAWYC/SBfQhEgdXmNy98OguOIZCuqF18wZwipvmGm7/hTgUkBPkBYD9aCAjwnIR1BKHXbN3eq37gqzoAwsIqmER+ScLElqxgWHPAjBiZhNpCD38bTx6l+F5bnjN89JgCy9AjsCtuRmSUxSiV4GDZFe+b0SdHefjNWwBLKSI7IRj/CgAM/QCjDKy2cNrAqtqOfX+d2/JvnuBuWQlcnB6QlkUU6PtBKqFCGBi2Y/+uwNc5UIeTKO05d78ByGSkoBUX54zAIfUbK26H0tNgsg0NNImdnxNrWZIAcCzj/0UU4FzQSyISgCC8zzNB7bZrBcig0f53hRq2nKARyb26VE+IdTqqANIVEUCmoqVaK4fTgQvX4bSO6ZyY+fxax3XROhuszXHB/2aYpmT8H3mzAAAAAElFTkSuQmCC"
					alt=""
					onError={() => setImgLoadError(true)}
					objectFit="contain"
				/>
			</div>
		);
	}

	return (
		<div className="w-[200px] h-[200px] flex items-center justify-center">
			<figure className="max-w-[119px] flex flex-col align-items justify-center">
				<CameraIcon className="mx-auto" />
				<figcaption
					className="text-xs text-gray-medium mt-4 whitespace-normal"
					aria-hidden="true"
				>
					Image not currently available
				</figcaption>
			</figure>
		</div>
	);
};

export default ProductImagePreview;
