import { IProduct } from '@b2b-frontend/types';
import clsx from 'clsx';
import Button from '@b2b-frontend/components/Button';
import useBasket from 'utils/useBasket';

interface IProps {
	onClick: (e: React.MouseEvent<Element, MouseEvent>) => void;
	isOutOfStock?: boolean;
	className?: string;
	disabled?: boolean;
	material?: IProduct['material'];
}

const AddToCartButton: React.FC<IProps> = ({
	onClick,
	isOutOfStock,
	material,
	className,
	...otherProps
}) => {
	const { itemState, isInBasket } = useBasket();

	const isSubmitting = material ? itemState[material] === 'adding' : false;
	const isInBasketAlready = isInBasket(material);

	const label = isInBasketAlready ? 'Update cart' : 'Add to cart';

	return (
		<Button
			isSubmitting={isSubmitting}
			onClick={onClick}
			disabled={isOutOfStock}
			submittingMessage={label}
			className={clsx(className, 'lg:text-2xs xl:text-xs')}
			{...otherProps}
		>
			{label}
		</Button>
	);
};

export default AddToCartButton;
