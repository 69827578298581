import { IDropdownOption } from '@b2b-frontend/types';

/**
 *
 * @param availableQuantity - The quantity of the product available to be added to the basket. If zero, the item is out of stock
 * @param minQuantity - The minimum quantity of the product that can be added to the basket
 * @param quantityInterval - Some products can only be added to the basket in multiples of a certain number
 * @param maxQuantity - The maximum quantity of the product that can be added to the basket
 */
export const getQuantityOptions = ({
	availableQuantity = 100,
	minQuantity = 1,
	quantityInterval = 1,
	maxQuantity = 100,
}: {
	availableQuantity?: number;
	minQuantity?: number;
	quantityInterval?: number;
	maxQuantity?: number;
}): IDropdownOption[] => {
	if (availableQuantity === 0 || minQuantity > availableQuantity) {
		return [{ label: 'Qty', id: '', unavailable: true }];
	}

	const itemCount = Math.floor(
		(maxQuantity
			? Math.min(availableQuantity, maxQuantity)
			: Math.max(availableQuantity - minQuantity, minQuantity)) / quantityInterval,
	);

	if (Number.isNaN(itemCount)) {
		throw new Error('Invalid quantity options');
	}

	return [
		{ label: 'Qty', id: '', unavailable: true },
		...new Array(itemCount).fill(true).map((_, index) => {
			const qty = minQuantity + index * quantityInterval;

			return {
				label: `${qty}`,
				id: `${qty}`,
			};
		}),
	];
};

export const swapArrayItems = <T = unknown>(
	array: T[],
	currentIndex: number,
	requestedIndex: number,
): T[] => {
	const arr = [...array];
	const target = arr[requestedIndex];

	arr[requestedIndex] = arr[currentIndex];
	arr[currentIndex] = target;

	return arr;
};
