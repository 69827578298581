import type { FC } from 'react';
import { useCallback, useEffect, useRef } from 'react';
import * as Sentry from '@sentry/nextjs';
import Link from '@b2b-frontend/components/Link';
import {
	createAddToCartEvent,
	createProductImpressionEvent,
} from '@b2b-frontend/utils/analytics/gtm';
import { useIsOnScreen } from '@b2b-frontend/utils/useIsOnScreen';
import { LOCALE } from '@b2b-frontend/constants';
import ProductPrices from '../ProductPrices';
import { getProductPreviewElementFromActiveView } from './util';
import { useViewModel } from './viewModel';
import { addToBasket, updateQuantityInCart } from 'utils/basket';
import { ICommonProductTileProps } from 'components/CommonProductTile/types';
import { useViewModifier } from 'utils/ViewModifierProvider';
import { useSituation } from 'utils/SituationProvider';
import { KEYS } from 'utils/SituationProvider/constants';

const CommonProductTile: FC<ICommonProductTileProps> = ({
	activeView,
	className,
	priceElementReferenceViewport = document?.body,
	listPosition,
	...product
}) => {
	const rootRef = useRef<HTMLDivElement>(null);
	const {
		addAlert,
		basket,
		handleProductClicked,
		isOutOfStock,
		itemMap,
		locale,
		mutate,
		sendDataToGTM,
		user,
	} = useViewModel({ activeView, product });
	const shouldShowPrices = !useViewModifier().hidePrices;

	const isOnScreen = useIsOnScreen(rootRef);

	const updateItemQuantity = useCallback(
		async ({ material, quantity }: { material: string; quantity: string }) => {
			const isInBasket = !!itemMap[material];

			await mutate(
				'/api/basket',
				{
					...basket,
					items: isInBasket
						? basket?.items.map(item => {
								if (item.material !== material) {
									return item;
								}

								return {
									...item,
									quantity: parseInt(quantity, 10),
								};
						  })
						: [
								...(basket?.items || []),
								{
									material,
									href: product.href,
									availableQuantity: product.availableQuantity,
									shippingQuantity: product.shippingQuantity,
									name: product.name,
									itemTotal: 0,
									yourPrice: product.yourPrice,
									quantity,
								},
						  ],
				},
				false,
			);

			try {
				if (isInBasket) {
					await updateQuantityInCart(material, quantity);
					addAlert({
						contents: (
							<>
								Product quantity updated.{' '}
								<Link href="/checkout">View checkout</Link>
							</>
						),
						level: 'success',
						timeout: 5000,
					});
				} else {
					const addToBasketRequest = await addToBasket(
						material,
						typeof quantity === 'string' ? parseInt(quantity, 10) : quantity,
					);

					if (addToBasketRequest.success && addToBasketRequest.data) {
						sendDataToGTM(
							createAddToCartEvent({
								product: {
									...product,
									...addToBasketRequest.data,
								},
								currencyCode: locale === LOCALE.NZ ? 'NZD' : 'AUD',
							}),
						);
					}

					addAlert({
						contents: (
							<>
								Product added to cart. <Link href="/checkout">View checkout</Link>
							</>
						),
						level: 'success',
						timeout: 5000,
					});
				}
			} catch (e) {
				addAlert({
					contents: 'Failed to update item quantity. Please try again later.',
					level: 'error',
					timeout: 7000,
				});
			}

			await mutate('/api/basket');
		},
		[
			addAlert,
			basket,
			itemMap,
			locale,
			mutate,
			product.availableQuantity,
			product.href,
			product.name,
			product.shippingQuantity,
			product.yourPrice,
			sendDataToGTM,
		],
	);

	const PreviewElement = getProductPreviewElementFromActiveView(activeView);

	useEffect(() => {
		if (product && shouldShowPrices && !product.yourPrice && !!user) {
			Sentry.captureException(
				new Error(
					`Product ${product.material} has no price when we should be able to show one`,
				),
				{
					extra: { product, activeView, user },
				},
			);
		}
	}, [activeView, product, shouldShowPrices, user]);

	const situation = useSituation();

	useEffect(() => {
		if (isOnScreen) {
			sendDataToGTM(
				createProductImpressionEvent({
					product,
					currencyCode: locale === LOCALE.NZ ? 'NZD' : 'AUD',
					positionInList: listPosition,
					pageCategory: situation[KEYS.PAGE_CATEGORY],
				}),
			);
		}
	}, [isOnScreen, listPosition, locale, product, sendDataToGTM, situation[KEYS.PAGE_CATEGORY]]);

	return (
		<PreviewElement
			product={product}
			showPrices={!!user}
			className={className}
			priceElement={
				shouldShowPrices && (
					<ProductPrices yourPrice={product.yourPrice} rrp={product.rrp} />
				)
			}
			// @ts-expect-error
			ref={rootRef}
			isOutOfStock={isOutOfStock}
			onProductClicked={handleProductClicked}
			addToBasket={updateItemQuantity}
			updateQuantity={updateItemQuantity}
		/>
	);
};

export default CommonProductTile;
