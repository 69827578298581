import React, { useMemo } from 'react';
import clsx from 'clsx';
import { getQuantityOptions } from '@b2b-frontend/utils/array';

interface IDropdownProps {
	value: string;
	onChange: (newValue: string) => void;
	label?: string;
	className?: string;
	disabled?: boolean;
	id: string;
	name: string;
	availableQuantity?: number;
	minQuantity?: number;
	quantityInterval?: number;
	maxQuantity?: number;
	showInvalidQuantity?: boolean; // whether the dropdown should be able to show the current value of `value`, even if it's not allowed from the `quantityInterval` rules
}

const QuantityDropdown: React.FC<IDropdownProps> = ({
	value,
	onChange,
	label,
	id,
	name,
	availableQuantity = 100,
	quantityInterval = 1,
	className = '',
	disabled = false,
	minQuantity = 1,
	maxQuantity,
	showInvalidQuantity = false,
}) => {
	const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		const newValue = event.target.value;

		onChange(newValue);
	};

	const options = useMemo(() => {
		const _options = getQuantityOptions({
			availableQuantity,
			minQuantity,
			maxQuantity,
			quantityInterval,
		});

		const hasValidValue = _options.some(option => option.id === value);

		if (hasValidValue === false && showInvalidQuantity === true) {
			/**
			 * Where the "Qty" placeholder <option> sits in the list,
			 * real items start after that.
			 */
			const PLACEHOLDER_LABEL_ITEM_INDEX = 0;

			_options.splice(PLACEHOLDER_LABEL_ITEM_INDEX + 1, 0, {
				id: value,
				label: value,
				unavailable: true,
			});
		}

		return _options;
	}, [quantityInterval, minQuantity, maxQuantity, availableQuantity]);

	const isDisabled = availableQuantity === 0 || disabled;

	return (
		<>
			<label htmlFor={id} className="sr-only">
				{label}
			</label>
			<select
				id={id}
				name={name}
				value={value}
				onChange={handleChange}
				className={clsx(
					'appearance-none no-bg py-3 sm:py-4 px-5 text-center border border-gray focus:outline-none focus:ring-2 focus:ring-opacity-75 focus:ring-gray focus:ring-offset-2 focus:border-black text-sm sm:text-xs',
					isDisabled
						? 'cursor-not-allowed bg-gray-light text-gray-medium'
						: 'cursor-pointer bg-white',
					value !== '' ? 'text-black' : 'text-gray-medium',
					className,
				)}
				disabled={isDisabled}
			>
				{options.map(option => (
					<option
						key={option.id}
						value={option.id}
						disabled={option.unavailable === true}
					>
						{option.label}
					</option>
				))}
			</select>
		</>
	);
};

export default QuantityDropdown;
