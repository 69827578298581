/*
  @see https://gist.github.com/luigircruz/f7bd9381834524e6395724017225c0ec
  Is a given element currently in the viewport?
  This hook tracks that information using
  IntersectionObserver.
*/
import { useState, useEffect } from 'react';

export function useIsOnScreen(elementRef: React.RefObject<HTMLElement>, defaultState = false) {
	const [isOnscreen, setIsOnscreen] = useState(defaultState);

	useEffect(() => {
		if (!elementRef.current) {
			return;
		}

		const observer = new window.IntersectionObserver((entries, observer) => {
			const [entry] = entries;
			setIsOnscreen(entry.intersectionRatio > 0);
		});

		observer.observe(elementRef.current);

		return () => {
			observer.disconnect();
		};
	}, [elementRef.current]);

	return isOnscreen;
}
